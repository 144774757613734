"use client";

import { cn } from "@/lib/utils";
import React, { ReactNode, useRef, useState } from "react";
import KatanaDouble from "./images/KatanaDouble";
import Link from "next/link";

export interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  className?: string;
}

export const Header = ({ className, ...rest }: HeaderProps) => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [headerHeight] = useState<number>(80);

  // useEffect(() => {
  //   const updateHeaderHeight = () => {
  //     if (headerRef.current) {
  //       setHeaderHeight(headerRef.current.offsetHeight);
  //     }
  //   };

  //   // Initial height update
  //   updateHeaderHeight();

  //   // Update height on window resize
  //   window.addEventListener("resize", updateHeaderHeight);

  //   return () => {
  //     window.removeEventListener("resize", updateHeaderHeight);
  //   };
  // }, []);

  return (
    <>
      <header
        ref={headerRef}
        className={cn(
          "fixed top-0 z-[50] w-webkit-fill w-moz-available flex items-center  justify-end  shadow-md md:shadow-xl bg-gray-800 border-b-2 border-honk-2 transition-all group",
          className
        )}
        style={{ height: `${headerHeight}px` }}
        {...rest}
      >
        <Link href={"/"} className="w-fit px-4">
          <em className="font-honk not-italic h-fit flex flex-row-reverse items-center gap-4 p-2 w-full justify-start">
            <KatanaDouble className=" size-14" />
            <span className="text-right text-xl md:text-2xl whitespace-nowrap">
              SMART SAMURAI
              <br />
              KRZYSZTOF KUTNIOWSKI
            </span>
          </em>
        </Link>
        {/* <SidebarTrigger className="absolute size-full opacity-0" /> */}
      </header>
    </>
  );
};

// commit3
