"use client";

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
  SidebarTrigger,
} from "@/components/ui/sidebar";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { useState } from "react";
import { MdClose, MdOutlineTouchApp } from "react-icons/md";

const StronaGlownaItems = [
  {
    href: "/",
    title: "O firmie",
  },
  {
    href: "/kontakt",
    title: "Kontakt",
  },
];

const OfertaItems = [
  {
    href: "/oferta/smart-home",
    title: "Smart Home",
  },
  {
    href: "/oferta/smart-home/kreator",
    title: "Kreator domu",
  },
  {
    href: "/oferta/web-development",
    title: "Web Development",
  },
  {
    href: "/oferta/serwery-nextcloud",
    title: "Serwery Nextcloud",
  },
  {
    href: "/oferta/sprzet",
    title: "Komputery osobiste",
  },
];

export function AppSidebar() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <SidebarProvider
      open={open}
      onOpenChange={() => setOpen((prev) => !prev)}
      className={cn("relative", open ? "" : "size-0")}
      defaultOpen={false}
      style={{
        // @ts-expect-error This component has --sidebar-width but it is not recognized and will throw an error about a non existent property.
        "--sidebar-width": "20rem",
        "--sidebar-width-mobile": "20rem",
      }}
    >
      <div className="fixed left-0 z-[50] top-4  flex items-center gap-2 flex-row text-honk-2 group">
        <MdOutlineTouchApp className="ml-4 transition-all  group-hover:animate-ping   size-10" />
        <SidebarTrigger className="size-full absolute top-0 left-0 opacity-0" />
        <div className="bg-honk-2/50 group-hover:bg-honk-3 p-2 rounded transition-all  md:block hidden">
          <p className="text-bold text-white uppercase text-base">Nawigacja</p>
        </div>
      </div>
      <SidebarTrigger />
      <Sidebar
        className="!border-honk-2 z-[100] p-0"
        variant="sidebar"
        side="left"
      >
        <SidebarHeader className="text-xl flex flex-row justify-between items-center  uppercase font-bold  !bg-gray-800 border-b-2 border-honk-2">
          <h2 className="text-honk-gradient bg-transparent w-full">MENU</h2>
          <SidebarTrigger className="size-10 hover:bg-transparent">
            <MdClose className="size-full  !stroke-white text-honk-3" />
          </SidebarTrigger>
        </SidebarHeader>
        <SidebarContent className="text-white bg-gray-800">
          <SidebarGroup className=" flex flex-col gap-2 ">
            <SidebarGroupLabel className="py-2 px-0 ">
              <h2 className="w-full h-fit px-2 bg-honk-4 text-white">
                Strona główna
              </h2>
            </SidebarGroupLabel>
            <SidebarGroupContent>
              <SidebarMenu>
                {StronaGlownaItems.map((item) => (
                  <SidebarMenuItem key={item.title}>
                    <SidebarMenuButton asChild>
                      <Link
                        href={item.href}
                        className="hover:!bg-honk-4 hover:!text-white"
                      >
                        <>
                          <SidebarTrigger className="size-full absolute top-0 left-0 opacity-0" />
                          <h3>{item.title}</h3>
                        </>
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
          <SidebarGroup className=" flex flex-col gap-2 ">
            <SidebarGroupLabel className="py-2 px-0 ">
              <h2 className="w-full h-fit px-2 bg-honk-4 text-white">Oferta</h2>
            </SidebarGroupLabel>
            <SidebarGroupContent>
              <SidebarMenu>
                {OfertaItems.map((item) => (
                  <SidebarMenuItem key={item.title}>
                    <SidebarMenuButton asChild>
                      <Link
                        href={item.href}
                        className="hover:!bg-honk-4 hover:!text-white"
                      >
                        <>
                          <SidebarTrigger className="size-full absolute top-0 left-0 opacity-0" />
                          <h3>{item.title}</h3>
                        </>
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </SidebarContent>

        <SidebarFooter className="bg-honk-4" />
      </Sidebar>

      <SidebarTrigger
        className={cn(
          "size-full absolute top-0 left-0 !cursor-default z-[51] hover:!bg-black/35 bg-black/35 transition-all duration-500",
          open ? "opacity-100" : "opacity-0"
        )}
      />
    </SidebarProvider>
  );
}
